<!-- @Author: Yu_Bo 2023-09-04 14:15:00-->
<template>
    <div class="help_main">
        <!-- 头部 -->
        <div class="help_main_top">
            <help-title type="operate" :list="list" @box="listNav"></help-title>
        </div>
        <!-- 内容 -->
        <div class="help_main_content">
            <div class="content_left">
                <div class="left_title">数字人直播操作手册</div>
                <div class="left_main scroll_bar_x">
                    <div class="title" :class="listIndex==index?'title_active':''" v-for="(item,index) in list" :key="index" @click="listNav(index)">{{ item.help_article_title }}</div>
                </div>
            </div>
            <div class="content_right" v-if="list.length">
                <div class="right_title">{{ title }}</div>
                <div class="right_main scroll_bar_x">
                    <div v-html="text"></div>
                </div>
                <div class="right_bot">
                    <div class="bot_txt" @click="listNav(listIndex-1)">
                        <template v-if="listIndex">
                            <i class="el-icon-back"></i>
                            {{ list[listIndex-1].help_article_title }}
                        </template>
                    </div>
                    <div class="bot_txt" @click="listNav(listIndex+1)">
                        <template v-if="listIndex<list.length-1">
                            {{ list[listIndex+1].help_article_title }}
                            <i class="el-icon-right"></i>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HelpTitle from './components/help_title'
export default {
    components: {HelpTitle},
    props: {},
    data() {
        return {
            listIndex:0,
            list:[],
            // 
            link_id:"",
            title:"",
            text:"",
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() {
        if(this.$route.params.id){
            this.link_id=this.$route.params.id
        }
        this.infoData()
     },

    methods: {
        // 获取左侧列表
        infoData(){
            var params = {
                id:1
            }
            this.$publicApi.helpTheme(params).then(res => {
                if (res.code == 1000) {
                    this.list = res.result.details
                    if(this.link_id){
                        this.list.forEach((item,index)=>{
                            if(item.help_article_id == this.link_id){
                                this.listIndex = index
                            }
                        })
                        this.infoDatails(this.link_id)
                    }else{
                        this.infoDatails(this.list[0].help_article_id)
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 获取右侧详情
        infoDatails(val){
            var params = {
                id:val
            }
            this.$publicApi.helpArticle(params).then(res => {
                if (res.code == 1000) {
                    this.title = res.result.title
                    this.text = res.result.content
                    this.$router.push({
                        path:'/help/operate/'+res.result.id
                    });
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 切换
        listNav(index){
            if(this.listIndex == index) return
            this.listIndex = index
            this.infoDatails(this.list[index].help_article_id)
        },
    }
}
</script>
<style lang='scss' scoped>
    .left_title{
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
    .left_main{
        width: 100%;
        height: calc(100% - 55px);
        padding: 0 20px;
        overflow-y: auto;
        .title{
            cursor: pointer;
            width: 100%;
            line-height: 25px;
            padding: 10px 0;
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .title_active{
            color: #2E4BF2;
        }
    }
    .right_title{
        width: 100%;
        height: 75px;
        line-height: 75px;
        padding: 0 20px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #F2F2F2;
    }
    .right_main{
        width: 100%;
        height: calc(100% - 150px);
        padding: 10px 20px;
        overflow-y: auto;
    }
    .right_bot{
        width: 100%;
        height: 75px;
        line-height: 75px;
        padding: 0 20px;
        border-top: 1px solid #F2F2F2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bot_txt{
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
        }
    }
</style>